const customCSSPropertiesMap: Record<string, string> = {
  activeDrawerWidth: '--awsui-active-drawer-width-8nglyc',
  breadcrumbsGap: '--awsui-breadcrumbs-gap-8nglyc',
  contentGapLeft: '--awsui-content-gap-left-8nglyc',
  contentGapRight: '--awsui-content-gap-right-8nglyc',
  contentHeight: '--awsui-content-height-8nglyc',
  containerFirstGap: '--awsui-container-first-gap-8nglyc',
  containerFirstOverlapExtension: '--awsui-container-first-overlap-extension-8nglyc',
  defaultMaxContentWidth: '--awsui-default-max-content-width-8nglyc',
  defaultMinContentWidth: '--awsui-default-min-content-width-8nglyc',
  footerHeight: '--awsui-footer-height-8nglyc',
  headerGap: '--awsui-header-gap-8nglyc',
  headerHeight: '--awsui-header-height-8nglyc',
  layoutWidth: '--awsui-layout-width-8nglyc',
  mainGap: '--awsui-main-gap-8nglyc',
  mainOffsetLeft: '--awsui-main-offset-left-8nglyc',
  mainTemplateRows: '--awsui-main-template-rows-8nglyc',
  maxContentWidth: '--awsui-max-content-width-8nglyc',
  minContentWidth: '--awsui-min-content-width-8nglyc',
  mobileBarHeight: '--awsui-mobile-bar-height-8nglyc',
  notificationsGap: '--awsui-notifications-gap-8nglyc',
  notificationsHeight: '--awsui-notifications-height-8nglyc',
  offsetTop: '--awsui-offset-top-8nglyc',
  offsetTopWithNotifications: '--awsui-offset-top-with-notifications-8nglyc',
  overlapHeight: '--awsui-overlap-height-8nglyc',
  navigationWidth: '--awsui-navigation-width-8nglyc',
  splitPanelReportedHeaderSize: '--awsui-split-panel-reported-header-size-8nglyc',
  splitPanelReportedSize: '--awsui-split-panel-reported-size-8nglyc',
  splitPanelHeight: '--awsui-split-panel-height-8nglyc',
  splitPanelMinWidth: '--awsui-split-panel-min-width-8nglyc',
  splitPanelMaxWidth: '--awsui-split-panel-max-width-8nglyc',
  toolsMaxWidth: '--awsui-tools-max-width-8nglyc',
  toolsWidth: '--awsui-tools-width-8nglyc',
  toolsAnimationStartingOpacity: '--awsui-tools-animation-starting-opacity-8nglyc',
  contentScrollMargin: '--awsui-content-scroll-margin-8nglyc',
  flashbarStackDepth: '--awsui-flashbar-stack-depth-8nglyc',
  flashbarStackIndex: '--awsui-flashbar-stack-index-8nglyc',
  flashbarStickyBottomMargin: '--awsui-flashbar-sticky-bottom-margin-8nglyc',
  stackedNotificationsBottomMargin: '--awsui-stacked-notifications-bottom-margin-8nglyc',
  stackedNotificationsDefaultBottomMargin: '--awsui-stacked-notifications-default-bottom-margin-8nglyc',
};
export default customCSSPropertiesMap;
